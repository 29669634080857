/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



.landing-page {
  font-family: Arial, sans-serif;
  text-align: center;
  color: #333;
}

.header {
  background-color: #f8f9fa;
  padding: 50px 20px;
}

.app-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.app-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.store-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.store-image {
  width: 150px;
  height: auto;
}

.screenshots {
  background-color: #ffffff;
  padding: 50px 20px;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 40px;
}

.screenshot-gallery {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.screenshot {
  width: 200px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer {
  background-color: #343a40;
  color: #ffffff;
  padding: 30px 20px;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-link {
  color: #ffffff;
  margin: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-text {
  font-size: 0.9rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hover-scale {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

@keyframes moveUpDown1 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes moveUpDown2 {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes moveUpDown3 {
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(20px);
  }
}

@media (max-width: 768px) {
  .landing-page {
    padding: 10px;
  }

  .content-container {
    flex-direction: column;
    padding: 20px;
  }

  .text-container {
    text-align: center;
    padding: 0;
  }

  .app-title {
    font-size: 28px;
  }

  .app-description {
    font-size: 16px;
    margin: 0 auto 20px;
    max-width: 90%;
  }

  .screenshot-gallery {
    flex-direction: column;
    gap: 15px;
  }

  .store-links {
    flex-direction: column;
    gap: 10px;
  }

  .store-image {
    width: 140px;
  }
}
